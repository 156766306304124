import {GET_ALL_DEPARTMENTS, GET_ALL_EMPLOYEES, GET_ALL_REGIONS, GET_ALL_STATUSES, GET_COMPANY_DATA, SET_SEARCH_FILTERS} from "./companyActionTypes";
import axios, { biApi } from '../../axiosApi';
import {CONFIG} from "../../constants/backendUrl";

export const getCompanyDataSuccess = (value) => ({type: GET_COMPANY_DATA, value});
export const getAllDepartments = (value) => ({type: GET_ALL_DEPARTMENTS, value});
export const getAllEmployees = (value) => ({type: GET_ALL_EMPLOYEES, value});
export const getAllRegions = (value) => ({type: GET_ALL_REGIONS, value});
export const getAllStatuses = (value) => ({type: GET_ALL_STATUSES, value});
export const setSearchFilters = (value) => ({type:SET_SEARCH_FILTERS, value})
export const getCompanyData = (hash) => {
    return async dispatch => {
        try {
            const response = await biApi.post('/api/login/getInfo', {hash: hash});
            dispatch(getCompanyDataSuccess(response.data));
        } catch (err) {
            console.log(err);
        }
    }
}
export const getSearchFilters = ({data, userHash}) => {
    return async dispatch => {
        try {
            const response = await axios.post(`${CONFIG['biBack']}/api/asista_app_history/getFilters`, data,{
                headers: {
                    Authorization: `Bearer ${userHash}`
                }
            });
            dispatch(getAllDepartments(response.data.data.departments))
            dispatch(getAllEmployees(response.data.data.employees))
            dispatch(getAllRegions(response.data.data.regions))
            dispatch(getAllStatuses(response.data.data.statuses))
        } catch (err) {
            console.log(err);
        }
    }
}
