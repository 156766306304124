import React, { useEffect, useState } from "react";
import './ApplicationBackModal.css'
import FormMultipleSelect from "../../Form/FormMultipleSelect/FormMultipleSelect";
import FormInput from "../../Form/FormInput/FormInput";
import RedButton from "../../RedButton/RedButton";
import { addDetailsToApplicationInProcess, setApplicationBackInProgress, setLastApplicationIsBack } from "../../../../Store/ApplicationsReducer/applicationsActions";
import { useDispatch, useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import { push } from "connected-react-router";
import BlueButton from "../../../ApplicationPageComponents/BlueButton/BlueButton";
import { setShowReturnApplicationModal } from "../../../../Store/GeneralReducer/generalActions";
import { useLocation } from "react-router-dom";
const ApplicationBackModal = (props) =>{
    const [reason, setReason] = useState("")
    const [swiper, setSwiper] = useState(null)
    const dispatch = useDispatch()
    const device_id = localStorage.getItem("device_id")
    const [selectedReason, setSelectedReason] = useState("")
    const reasons = ["перестало работать", "не доделали", "сделали плохо", "это новая задача"]
    const checkForSame = () => {
        if(reasons.includes(reason)){
            return true
        }
        return false
    }
    const goToApplication = () => {
        dispatch(setShowReturnApplicationModal(false))
    }
    const returnApplication = async () => {
        const applicationHash = props.hash
        const obj = {
            hash: applicationHash,
            comment: selectedReason? selectedReason: reason? reason: ""
        }
        await dispatch(addDetailsToApplicationInProcess(obj))
        await dispatch(setApplicationBackInProgress(props.hash))
        if(props.needTrackbutton){
            swiper.slideNext();
        }else{
            device_id?dispatch(push(`/application/${device_id}/${props.hash}`)):dispatch(push(`/restore-session`))
            dispatch(setShowReturnApplicationModal(false))
            device_id && window.location.reload()
        }
        
    }
    
    return(
        <div className='ReturnApplication__wrapper'>
            <Swiper
                // install Swiper modules
                spaceBetween={0}
                slidesPerView={1}
                onSwiper={(swiperL) => {
                    setSwiper(swiperL);
                }}
                draggable={false}
                allowTouchMove={false}
                style={{minWidth: 326}}
            >
                <SwiperSlide
                    style={{padding: 2}}
                >
                    <div className="ReturnApplication">
                        <div className='ReturnApplication__title'>Возврат заявки в работу:</div>
                        <div className='ReturnApplication__description'>Пожалуйста укажите причину возврата для того, чтобы мы сделали наш сервис лучше, а ваши ИТ-системы еще надежнее.</div>
                        {
                            !selectedReason?
                            <input className='ReturnApplication__input' 
                                disabled={checkForSame()} 
                                onChange={(e)=>setReason(e.target.value)} 
                                placeholder='Выберите одну из причин или напишите свою' 
                                value={reason}>
                            </input>:
                            <div className='ReturnApplication__input'>
                                <div className={`ReturnApplication__reason ${selectedReason==="это новая задача"?"ReturnApplication__reason_selected_green":"ReturnApplication__reason_selected"}`} onClick={()=>setSelectedReason("")}>
                                    {selectedReason}
                                </div>
                            </div>
                        }


                        <div className='ReturnApplication__reasons_list'>
                            {reasons.map(element => {
                                return (
                                    <button key={element} className={`ReturnApplication__reason ${selectedReason || reason? "ReturnApplication__reason_disabled": ""}`}
                                        disabled={selectedReason || reason}    
                                        onClick={()=>setSelectedReason(element)}
                                    >{element}</button>
                                )
                            })}
                        </div>
                        {
                            selectedReason==="это новая задача"?
                            <a
                                target="_blank"
                                href={`/application/${device_id}/newticket`}
                                onClick={()=>{
                                    dispatch(setShowReturnApplicationModal(false))
                                }}
                                className="NewApplication__button"
                                disabled={!reason && !selectedReason}
                            >Подать заявку</a>
                            :
                            <RedButton
                                name="Вернуть в работу"
                                onClickHandler={returnApplication}
                                disabled={!reason && !selectedReason}
                            />
                        }
                        
                    </div>
                    
                </SwiperSlide>
                <SwiperSlide
                >
                    <div className="ReturnApplication">
                        <div className='ReturnApplication__title ReturnApplication__title_red'>Заявка № {props.applicationId}</div>
                        <div className="ReturnApplication__description">
                            <b>принята и зафиксирована в нашей CRM-системе ИТ-специалист свяжется с вами через 15 минут</b>
                        </div>
                        <a className="goToApplication" target="_blank" href={`${device_id?`/application/${device_id}/${props.hash}`:`/restore-session`}`} onClick={goToApplication}>Отслеживать статус заявки</a>
                    </div>
                    
                </SwiperSlide>
            </Swiper>
                
        </div>
    )
}

/*
    props.bgOnClick - событие по клику на фон
    props.modalStatus - статус модального окна (закрытое / открытое)
    props.children - элемент для того, чтобы внутрь этого контейнера можно было вставлять другие html - элементы
*/


export default ApplicationBackModal;