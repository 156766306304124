import { notification } from 'antd'

export const showNotification = (type, options) => {
    notification[type]({
        message: options.message,
        description: options.description || '',
        duration: options.duration || 3,
        placement: 'bottomRight',
    })
}
