import { useEffect, useState } from "react"
import "./Chat.css"
import { useDispatch, useSelector } from "react-redux"
import Send from "../../../assets/icons/send_comment.svg"
import Files from "../../../assets/icons/add_files.svg"
import ChatMessage from "./ChatMessage/ChatMessage"
import AsistaLogo from "../../../assets/icons/asista_chat.svg"
import ProjectIcon from "../../../assets/department_icons/project_managers.svg"
import OutstuffIcon from "../../../assets/department_icons/outstuff.svg"
import FirstLine from "../../../assets/department_icons/line_1.svg"
import SecondLine from "../../../assets/department_icons/line_2.svg"
import ThirdLine from "../../../assets/department_icons/line_3.svg"
import Viezdnie from "../../../assets/department_icons/viezdnie.svg"
import ServersSupport from "../../../assets/department_icons/servers_support.svg"
import Buhgalteria from "../../../assets/department_icons/buhgalteria.svg"
import Service from "../../../assets/department_icons/service.svg"
import Office from "../../../assets/department_icons/office.svg"
import Sells from "../../../assets/department_icons/sells.svg"
import Notifications from "../../../assets/icons/notification.png"
import NotificationsOff from "../../../assets/icons/notification-off.png"
import { setAddFileModal } from "../../../Store/ApplicationsReducer/applicationsActions"
import { ws } from "../../../utils/chatWebSocket"
import {  getChatFilesId, getChatId, setChatFiles, setChatMessages, setChatUserInfo, setTempMessage } from "../../../Store/ChatReducer/chatActions"
import { useParams } from "react-router"
import ChatAddFiles from "./ChatAddFiles/ChatAddFiles"
import { ChatImageModal } from "./ChatImageModal/ChatImageModal"
import moment from "moment"
import { subscribe } from "./WebPush/WebPush"
export default function Chat(props){
    const [value, setValue] = useState("")
    const dispatch = useDispatch()
    const inputHandler = (e) => {
        setValue(e.target.value)
    }
    const params = useParams()
    const currentApplicationTime = useSelector(state => state.chat.chatApplicationTime);
    const currentApplication = useSelector(state=> state.applications.currentApplicationData)
    const photo = currentApplication? `data:image/jpg;base64, ${currentApplication.image}` : null;
    const chatApplicationDone = currentApplication?.status?.toLowerCase()==="завершено"
    const tempMessage = useSelector(state=>state.chat.tempMessage)
    const clientName = useSelector(state => state.applications.clientName.name)
    const chatMessages = useSelector(state=>state.chat.chatMessages)
    const chatFiles = useSelector(state=>state.chat.chatAttachedFiles)
    const chatId = useSelector(state => state.chat.chatId)
    const [finishedTime, setFinishedTime] = useState()
    const currentApplicationExists = useSelector(state=>state.chat.applicationExists)
    function convertFilesToFormData(files) {
        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
          formData.append('files', files[i]);
        }
        return formData;
    }
    const departmentImage = (department) => {
        switch(department){
            case "PRB Проблема/замечания":
            case "Комплексные задачи":    
            case "Напоминание":
            case "Обучение":
            case "Проектный менеджер":
            case "Рекламация\\Обратная связь":
            case "Системы":
            case "Улучшение работы":
                return ProjectIcon;
            case "Аутстаффинг":
                return OutstuffIcon;
            case "Бухгалтерия":
                return Buhgalteria;
            case "Выездные специалисты":
            case "Логистика":
            case "Монтаж сетей":
            case "Плановая профилактика":
                return Viezdnie;
            case "Заправка/Ремонт офисной техники":
                return Office;
            case "Обслуживание серверов":
                return ServersSupport;
            case "Сервисный центр":
                return Service;
            case "Продажи / Закуп":
                return Sells;
            case "Удаленная поддержка 1 линия":
                return FirstLine;
            case "Удаленная поддержка 2 линия":
                return SecondLine;
            case "Специалисты 3 линии поддержки":
                return ThirdLine;
            default:
                return FirstLine
        }
    }
    const formOnsubmit = async (e) => {
        if(value.trim()!=="" || chatFiles.length > 0){
            e.preventDefault();
            const chatMessagesCopy = [...chatMessages];
            if(chatFiles){
                const formData = convertFilesToFormData(chatFiles);
                const sendImages = [];
                const sendFiles = [];
                const tempFiles = chatFiles.map(file => {
                    return {
                        _id: file.lastModified,
                        mimetype: "file",
                        originalFilename: file.name,
                        size: file.size,
                        extension: "File",
                        url: "Loading",
                        path: "Loading",
                        createdAt: file.lastModified,
                    }
                })
                setValue("")
                dispatch(setChatFiles([]))
                dispatch(setTempMessage({
                    _id: (chatMessagesCopy.length+1).toString(),
                    type: "text",
                    payload: {
                        text: value,
                        images: [],
                        files: tempFiles
                    },
                    dialogId: chatId,
                    sender: "guest",
                    senderName: clientName,
                    senderType: "client",
                    readBy: [
                        {
                            readAt: "",
                            _id: ""
                        }
                    ],
                    time: new Date().toString(),
                }))
                const filesRenponse = await dispatch(getChatFilesId({files:formData, hash: params.hash, deviceId: params.id}))
                for(let i = 0; i < filesRenponse.length; i ++ ){
                    filesRenponse[i].mimetype.split("/")[0]==='image' ?
                    sendImages.push(filesRenponse[i]._id) :
                    sendFiles.push(filesRenponse[i]._id)
                }
                ws.emit('sendMessage', {
                    text: value,
                    dialogId: chatId,
                    files: sendFiles,
                    images: sendImages
                })
            
            }else{
                dispatch(setTempMessage({
                    _id: (chatMessagesCopy.length+1).toString(),
                    type: "text",
                    payload: {
                        text: value,
                        images: [],
                        files: []
                    },
                    dialogId: chatId,
                    sender: "guest",
                    senderName: clientName,
                    senderType: "client",
                    readBy: [
                        {
                            readAt: "",
                            _id: ""
                        }
                    ],
                    time: new Date().toString(),
                }))
                setValue("");
                ws.emit('sendMessage', {
                    text: value,
                    dialogId: chatId
                })
            }


        }else(
            e.preventDefault()
        )
        
    }

    
    useEffect(()=>{
        ws.connect()
        return () => {
            ws.disconnect()
        }
    }, [])

    useEffect(()=>{
        if(currentApplicationExists){
            //getDialogId
            dispatch(getChatId({hash: params.hash, id: params.id}))
        }
    }, [currentApplicationExists])
    useEffect(()=>{
        if (chatId) {
            ws.emit('joinDialog', { dialogId: chatId })
        }
        ws.on("connect", ()=>{
            ws.emit("getMyInfo", (info)=>{
                dispatch(setChatUserInfo(info))
            })
        })
        // ws.on("applicationInfo", (applicationInfo)=>{
        //     dispatch(setChatApplicationTime(applicationInfo))
        // })
        ws.on('messages', (messages) => {
            dispatch(setChatMessages(messages.reverse()))
        })
        
        return () => {
            ws.off('joined')
            ws.off('messages')
        };
    }, [chatId, dispatch])

    useEffect(() => {
        ws.on('message', (message) => {
            const chat = document.getElementsByClassName("Chat__view")[0];
            chat.scrollTop = chat.scrollHeight;
            //таймаут для красоты появления лоадера, если его не будет будет просто моргать лоадер, что не есть красиво, а полсекунды никто не заметит
            setTimeout(()=>{
                if(message.dialogId===chatId){
                    dispatch(setTempMessage(null))
                    dispatch(setChatMessages([message,...chatMessages]))
                }
            }, 500)
        })
        return () => {
            ws.off('message')
        }
    }, [chatMessages, dispatch])

    useEffect(()=>{
        if(currentApplicationTime){
            setFinishedTime(currentApplicationTime.finishedAt)
        }
    }, [currentApplicationTime])
    return(
        <>
            <div className="Chat">
                <div className="Chat__view">
                    {
                        tempMessage?
                            <ChatMessage
                                key={tempMessage._id}
                                images={tempMessage.payload?.images}
                                files={tempMessage.payload?.files}
                                name={tempMessage.senderName}
                                time={new Date(tempMessage.time).toLocaleTimeString([], {hour: "2-digit", minute: "2-digit"})}
                                message={tempMessage.payload?.text}
                                userPhoto={AsistaLogo}
                                statusSending = {true}
                            />
                        :null
                    }
                    {
                        chatMessages?.map((message)=>{
                            return(
                                <ChatMessage
                                    key={message._id}
                                    images={message.payload?.images}
                                    files={message.payload?.files}
                                    name={message.senderName}
                                    time={new Date(message.time).toLocaleTimeString([], {hour: "2-digit", minute: "2-digit"})}
                                    message={message.payload?.text}
                                    userPhoto={AsistaLogo}
                                />
                            )
                            
                        })
                    }
                </div>
                {
                    chatApplicationDone || currentApplication?.status?.toLowerCase()==="отменено"?
                    <div className="Chat__ended">
                        <div className={`Chat__end_title ${chatApplicationDone?"Chat__end_green":"Chat__end_grey"}`}>
                            <img
                                className='Chat__end_user'
                                src={photo}
                                alt={props.name}
                            />
                            {moment.utc(finishedTime).format("DD.MM.YY")} | {moment.utc(finishedTime).format("HH:mm")} | {currentApplication?.status?.toLowerCase()==="завершено"?"Заявка закрыта":"Заявка отменена"}
                            <div className="Application__infoheader_logo">
                                <img src={departmentImage(currentApplication?.division)} alt="logo"/>
                            </div>
                        </div>
                        <div className="Chat__end_result">
                            <div className="Chat__end_result_title">{chatApplicationDone?"Результат":"Причины отмены"}</div>
                            <div className="Chat__end_result_text">{currentApplication?.eventresult}</div>
                        </div>
                    </div>
                    :
                    <form className="Chat__form" onSubmit={(e)=>formOnsubmit(e)}>
                    
                        <input placeholder="Сообщение" className="Chat__input" value={value} onChange={inputHandler}/>
                        <button className="Chat__add_files" type="button" onClick={()=>dispatch(setAddFileModal(true))}>
                            <img className="Chat__add_img" src={Files} alt="button"/>
                        </button>
                        <button className="Chat__submit" disabled={tempMessage?true: false} type="submit">
                            <img className="Chat__submit_img" src={Send} alt="button"/>
                        </button>
                    </form>
                }
                
            </div>
            <ChatAddFiles
                onClickHandler = {(e)=>{
                    formOnsubmit(e)
                    dispatch(setAddFileModal(false))
                }}
            />
            <ChatImageModal/>
        </>
    )
}