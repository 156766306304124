import { useState } from "react";
import { copyToClipboard } from "../../../utils/functions/textFunctions";
import "./VksReadyModal.css"
import {ReactComponent as LinkIcon} from "../../../assets/icons/copy_link.svg"
import { sendYMAnalytics } from "../../../utils/metrika";
export default function VksReadyModal ({vksParts, currentURL, vksLink, id}){
    const [copying, setCopying] = useState(false)
    const localCopy = () =>{
        setCopying(true)
        copyToClipboard(`${currentURL}/conference/invite?room=${vksParts.friendly_mid}`)
        setTimeout(()=>{
            setCopying(false)
        }, 1000)
    }
    const goToRoom = () => {
        sendYMAnalytics("vks-open", {id: id})
        window.location.assign(vksLink)
    }
    return(
        <div>
            <div>
            Для одного клиента-компании мы создаем комнату для проведения внутренних конференций.
            Вы можете отправить ссылку на нее всем участникам конференции и в указанное время решать рабочие вопросы.
            Нажмите «Войти в комнату», и вы автоматически попадете в комнату, созданную специально для вашей компании. 
            <br/>
            <br/>*Для абонентов IT Support Group данный сервис является бесплатным. Мы не используем персональные данные, озвученные в ходе видеоконференции.
                
                <div className='Modal__buttons'>
                    <button type="button" onClick={localCopy} className="VksInviteModule__blue_button" >
                        <LinkIcon/>
                        Поделиться
                        <div  className={`VksReadyModal__notification ${copying?"":"VksReadyModal__notif_hidden"}`}>Ссылка скопирована</div>
                    </button>
                    <button type="button" onClick={goToRoom} className="VksInviteModule__form_submit">
                        Войти в комнату
                    </button>   
                </div>
            </div>
        </div>

    )
}