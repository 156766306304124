import axios from "../../axiosApi";
import WithLoader from "../../hoc/WithLoader/WithLoader";
import './ChangeAccessPage.css';
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import LayoutApplicationPage from "../../Components/ApplicationPageComponents/LayoutApplicationPage/LayoutApplicationPage";
import { LogoSidebar } from "../../constants/UI/LogoSidebar";
import { push } from "connected-react-router";
import PreviousApplicationMenu from "../../Components/ApplicationPageComponents/PreviousApplicationMenu/PreviousApplicationMenu";
import { changeAccessTask, setApplicationBackInProgress } from "../../Store/ApplicationsReducer/applicationsActions";
import FunctionBlock from "../../Components/UI/FunctionBlock/FunctionBlock";
import OpenAccessForm from "../../Components/OpenAccessPageComponent/OpenAccessForm/OpenAccessForm";
import FormBlock from "../../Components/UI/FormBlock/FormBlock";
import FormInput from "../../Components/UI/Form/FormInput/FormInput";
import FormSelect from "../../Components/UI/Form/FormSelect/FormSelect";
import HrModalWindow from "../../Components/OpenAccessPageComponent/HRModalWindow/HrModlaWindow";
import FormCommentInput from "../../Components/UI/Form/FormCommentInput/FormCommentInput";
import AddInput from "../../Components/OpenAccessPageComponent/AddInput/AddInput";
import ModalPeriod from "../../Components/SearchResultsComponents/ModalPeriod/ModalPeriod";
import Hint from "../../Components/UI/Hint/Hint";
import BlueButton from '../../Components/ApplicationPageComponents/BlueButton/BlueButton'
import { getClientName, saveId, getLastApplication } from "../../Store/ApplicationsReducer/applicationsActions";
import { supportAsista } from "../../constants/clientRoute";
import FormBlockTitle from "../../Components/UI/FormBlock/FormBlockTitle/FormBlockTitle";
import RedButton from "../../Components/UI/RedButton/RedButton";
import WhiteButton from "../../Components/ApplicationPageComponents/WhiteButton/WhiteButton";

const ChangeAccessPage = (props) => {
    const lastApplication = useSelector(state => state.applications.lastApplication);
    let leftSide;
    const id = props.match.params.id;
    const [hrModal, setHrModal] = useState(false);
    const dispatch = useDispatch();
    const [hintState, setHintState] = useState({
        emailGroup: false,
        needMove: false
    })
    const [isExpiredDate, setIsExpiredDate] = useState(false);
    const dateExpirationHandler = (date) => {
        let currMonth = new Date().getMonth() + 1;
        let currDay = new Date().getUTCDate();
        let applicationDateMonth = Number(date.slice(3, 5));
        let applicationDateDay = Number(date.slice(0, 3));
        let diff =
            (currMonth - applicationDateMonth) * 31 + currDay - applicationDateDay;
        if (Math.abs(diff) >= 31) {
            setIsExpiredDate(true);
        }
    };
    const clientName = useSelector(state => state.applications.clientName)
    const [calendar, setCalendar] = useState(false)
    const [changeAccessState, setChangeAccessState] = useState(
        {
            applicationType: "changeAccess",
            user: {
                fullname: "",
                phone: ""
            },
            device: {
                oldId: "",
                newId: "",
                used: false
            },
            needMove: "",
            comment: "",
            senderId: id
        }
    )

    useEffect(() => {
        dispatch(getClientName(id));
        dispatch(getLastApplication(id));
        dispatch(saveId(id));
        if (lastApplication?.date !== null && lastApplication?.date)
            dateExpirationHandler(lastApplication.date);

    }, [dispatch, id]);


    const [disabled, setDisabled] = useState(true);
    const [inputState, setInputState] = useState();

    const submitHandler = async (event) => {
        event.preventDefault();
        dispatch(changeAccessTask(changeAccessState, id))

    }


    const isBackInProgressHandler = () => {
        dispatch(setApplicationBackInProgress(lastApplication.ref));

    }

    if (lastApplication && !!lastApplication.result) {
        leftSide = (
            <>
                <PreviousApplicationMenu
                    title="Предыдущая заявка"
                    name={lastApplication.topic}
                    date={lastApplication.date}
                    description=""
                    buttonName="Вернуть в работу"
                    clicked={isBackInProgressHandler}
                />
                {
                    clientName.cua_access_management ?
                        <WhiteButton
                            name="Прием или увольнение сотрудника"
                            clicked={() => { setHrModal(true) }}
                        /> : null
                }
            </>
        )
    }

    const emailChecker = (event) => {
        const { name, value } = event.target;
        const symbols = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
        if (symbols.test(value)) {
            setInputState(prevState => {
                return { ...prevState, [name]: value }
            });
            if (inputState["email"]) {
                setDisabled(false);
            }
        } else {
            setDisabled(true);
        }
    }




    const center = (
        <>
            <OpenAccessForm
                onSubmit={submitHandler}
                title="Запрос на смену доступов"
            >
                <FormBlock title="Данные пользователя">
                    <FormInput
                        name="fullname"
                        label="ФИО пользователя *"
                        required
                        value={changeAccessState.user.fullname}
                        onChangeHandler={(e) => { setChangeAccessState({ ...changeAccessState }, changeAccessState.user.fullname = e.target.value) }}
                    />
                    <FormInput
                        name="phone"
                        label="Контактный телефон *"
                        required
                        value={changeAccessState.user.phone}
                        onChangeHandler={(e) => { setChangeAccessState({ ...changeAccessState }, changeAccessState.user.phone = e.target.value) }}
                    />
                </FormBlock>
                <FormBlock title="Данные старого рабочего места">
                    <FormInput
                        name="old_device_id"
                        label="ID оборудования"
                        required
                        value={changeAccessState.device.oldId}
                        onChangeHandler={(e) => { setChangeAccessState({ ...changeAccessState }, changeAccessState.device.oldId = e.target.value) }}
                    />
                    <FormBlockTitle>Данные нового рабочего места</FormBlockTitle>
                    <FormSelect
                        options={[
                            { value: 'new', label: "Новое оборудование" },
                            { value: 'old', label: "Ранее использовалось" }
                        ]}
                        placeholder='Оборудование'
                        onChangeHandler={(e) => {
                            e.value === "new" ?
                                setChangeAccessState({ ...changeAccessState }, changeAccessState.device.used = false)
                                : e.value === "old" ?
                                    setChangeAccessState({ ...changeAccessState }, changeAccessState.device.used = true)
                                    : setChangeAccessState({ ...changeAccessState }, changeAccessState.device.used = false)
                        }}
                    />
                    {
                        changeAccessState.device.used ?
                            <FormInput
                                name="old_used_device_id"
                                label="ID оборудования"
                                required
                                value={changeAccessState.device.newId}
                                onChangeHandler={(e) => { setChangeAccessState({ ...changeAccessState }, changeAccessState.device.newId = e.target.value) }}
                            /> : null
                    }
                </FormBlock>
                <FormBlock title="Необходимо перенести"
                    hint='Укажите программы и данные, которые необходимо перенести.'
                    setHintShow={() => { setHintState({ ...hintState, needMove: true }) }}
                    setHintHidden={() => { setHintState({ ...hintState, needMove: false }) }}
                    hintState={hintState.needMove}
                >
                    <FormCommentInput
                        placeholder="Почту, 1С, файлы с рабочего стола"
                        name='needMove'
                        value={changeAccessState.needMove}
                        onChangeHandler={e => { setChangeAccessState({ ...changeAccessState, needMove: e.target.value }) }}
                    />
                </FormBlock>
                <FormBlock title="Дополнительный комментарий">
                    <div className="ResultBlock">
                        <FormCommentInput
                            placeholder="Дополните заявку, например: сотрудник переехал в другой кабинет, для уточнения можно позвонить мне по номеру"
                            name='comment1'
                            value={changeAccessState.comment}
                            onChangeHandler={e => { setChangeAccessState({ ...changeAccessState, comment: e.target.value }) }}
                        />
                        <BlueButton
                            type="submit"
                            name='Отправить заявку'
                        />
                    </div>
                </FormBlock>

            </OpenAccessForm>
            {
                hrModal ?
                    <HrModalWindow
                        bgOnClick={() => { setHrModal(false) }}
                        modalStatus={hrModal}
                        id={id}
                    />
                    : null
            }
        </>
    )

    const left = (
        <>
            {leftSide}
        </>
    )

    return (
        <div>
            <LogoSidebar />
            <LayoutApplicationPage createNewApplication={() => { dispatch(push(`/prelogin/${id}`)) }} left={left} center={center}> </LayoutApplicationPage>
        </div>
    )
}

export default WithLoader(ChangeAccessPage, axios);