import { useSelector } from "react-redux";
import axios from "../../axiosApi";
import { ADD_FEEDBACK_STATE, ADD_QUESTIONS, EXCELL_ACCESS,COOKIES, OPEN_FAQ_QUESTION, SHOW_CHAT, SHOW_FAQ, SHOW_RETURN_APPLICATION_MODAL, SHOW_PATCH_NOTES, SET_READ_PATCHES } from "./generalActionTypes";

export const setShowReturnApplicationModal = (value) => ({type: SHOW_RETURN_APPLICATION_MODAL, value});
export const setFaqOpen = (value) => ({type: OPEN_FAQ_QUESTION, value});
export const showFaq = (value) => ({type: SHOW_FAQ, value})
export const addQuestions = (value) => ({type: ADD_QUESTIONS, value})
export const setFeedback = (value) => ({type: ADD_FEEDBACK_STATE, value})
export const setExcelAccess = (value) => ({type: EXCELL_ACCESS, value})
export const setShowChat = (value) =>({type: SHOW_CHAT, value})
export const setCookies = (value) => ({type: COOKIES, value})
export const setShowPatchNotes = (value) => ({type: SHOW_PATCH_NOTES, value})
export const setPatchesRead = (value) => ({type: SET_READ_PATCHES, value})

export const getQuestionsName = () =>{
    return async dispatch => {
        const response = await axios.get("/uploads/faqData.json");
        let obj = {};
        response.data.faqQuestions.map(elem =>{
            return obj[elem.name] = false;
        })
        let feedBackObj = {};
        response.data.faqQuestions.map(elem =>{
            return feedBackObj[elem.name+"_useful"] = "";
        })
        let questions = [];
        response.data.faqQuestions.map(elem=>{
            let questionObj = {
                type: elem.type,
                body: elem.body, 
                title: elem.title,
                name: elem.name
            };
            questions.push(questionObj)
            return questions;
        })
        await dispatch(setFaqOpen(obj))
        await dispatch(addQuestions(questions))
        await dispatch(setFeedback(feedBackObj))
    }
}

export const sendFaqFeedback = (data) => {
    return async dispatch =>{
        try{
            const response = await axios.post("/faq", data)
        }catch(e){
            console.log(e);
        }
    }
}
