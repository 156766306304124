import axios from "axios";
import { SET_CHAT_MESSAGES, SET_CHAT_USER_INFO, SET_CHAT_ID, SET_CHAT_FILES, SET_CHAT_SELECTED_PHOTO, SET_TEMP_MESSAGE, SET_APPLICATION_EXISTS, SET_CHAT_APPLICATION_TIME, SET_NOTIFICATIONS_WORKING } from "./chatActionTypes";
import { CONFIG } from "../../constants/backendUrl";

export const setApplicationExists = (value) => ({type: SET_APPLICATION_EXISTS, value})
export const setChatApplicationTime = (value) => ({type: SET_CHAT_APPLICATION_TIME, value})
export const setChatUserInfo = (value) => ({type: SET_CHAT_USER_INFO, value}) 
export const setChatMessages = (value) => ({type: SET_CHAT_MESSAGES, value})
export const setChatFiles = (value) => ({type: SET_CHAT_FILES, value})
export const setTempMessage = (value) => ({type: SET_TEMP_MESSAGE, value})
export const setChatId = (value) => ({type: SET_CHAT_ID, value})
export const setChatSelectedPhoto = (value) => ({type: SET_CHAT_SELECTED_PHOTO, value})
export const setNotificationsWorking = (value) => ({type: SET_NOTIFICATIONS_WORKING, value})
export const getChatId = ({hash, id}) => {
    return async dispatch =>{
        try{
            const response = await axios.get(`${CONFIG.chatApi}/dialogs/dialogIdByApplicationUUID/${hash}`, {
                headers: {
                    Authorization: `Guest:${id}:${hash}`
                }
            })
            dispatch(setChatId(response.data))
        }catch(e){
            console.log(e);
        }
    }
}
export const getChatFilesId = ({hash, deviceId, files}) => {
    return async dispatch =>{
        try{
            const response = await axios.post(`${CONFIG.chatApi}/media/upload/`, files, {
                headers: {
                    Authorization: `Guest:${deviceId}:${hash}`
                }
            })
            return response.data;
        }catch(e){
            console.log(e);
        }
    }
}

export const getApplicationExists = ({hash, deviceId}) => {
    return async dispatch =>{
        try{
            const response = await axios.get(`${CONFIG.chatApi}/dialogs/exists/${hash}`, {
                headers: {
                    Authorization: `Guest:${deviceId}:${hash}`
                }
            })
            dispatch(setApplicationExists(response.data)) 
        }catch(e){
            dispatch(setApplicationExists(false)) 
            console.log(e);
        }
    }
}
export const getChatApplicationTime = ({hash, deviceId}) => {
    return async dispatch =>{
        try{

            const response = await axios.get(`${CONFIG.chatApi}/dialogs/getApplicationTime/${hash}`,  {
                headers: {
                    Authorization: `Guest:${deviceId}:${hash}`
                }
            });
            dispatch(setChatApplicationTime(response.data)) 
        }catch(e){
            console.log(e);
        }
    }
}
export const subscribeNotifications = ({hash, deviceId}) => {
    return async dispatch =>{
        try{

            await axios.post(`${CONFIG.chatApi}/webpush/subscribe`, {},  {
                headers: {
                    Authorization: `Guest:${deviceId}:${hash}`
                }
            });
            
        }catch(e){
            console.log(e);
        }
    }
}