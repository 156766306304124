import {
    SET_CHAT_MESSAGES,
    SET_CHAT_ID,
    SET_CHAT_FILES,
    SET_CHAT_SELECTED_PHOTO,
    SET_TEMP_MESSAGE,
    SET_APPLICATION_EXISTS,
    SET_CHAT_APPLICATION_TIME,
    SET_NOTIFICATIONS_WORKING
} from './chatActionTypes'
import update from 'immutability-helper';

const initialState = {
    chatInfo: {},
    chatId: "",
    chatUserInfo: {},
    chatMessages: [],
    chatAttachedFiles: [],
    chatSelectedPhoto: "", 
    tempMessage: null,
    applicationExists: false,
    chatApplicationTime: {},
    notificationsWorking: false
};

const chatReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_NOTIFICATIONS_WORKING:
            return {...state, notificationsWorking: action.value}
        case SET_CHAT_APPLICATION_TIME:
            return {...state, chatApplicationTime: action.value}
        case SET_APPLICATION_EXISTS:
            return {...state, applicationExists: action.value}
        case SET_TEMP_MESSAGE:
            return {...state, tempMessage: action.value}
        case SET_CHAT_SELECTED_PHOTO: 
            return {...state, chatSelectedPhoto: action.value}
        case SET_CHAT_FILES: 
            return {...state, chatAttachedFiles: action.value}
        case SET_CHAT_ID: 
            return {...state, chatId: action.value}
        case SET_CHAT_MESSAGES: 
            return {...state, chatMessages: action.value}
        default:
            return state;
    }
};

export default chatReducer;
