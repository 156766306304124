import './InvisionTrialForm.css';
import { InvLogoSidebar } from '../../../constants/UI/InvLogoSidebar';
import LogoFrameInv from '../../ApplicationPageComponents/Logo/LogoInv';
import {ReactComponent as Clip} from "../../../assets/icons/icon-clip.svg";
import {ITSGContact} from "../../../constants/Contact/ITSGContact/ITSGContact";
import {FooterInfo} from "../../../constants/Contact/FooterInfo/FooterInfo";
import { useRef } from 'react';
import {ReactComponent as Instagram} from "../../../assets/icons/instagram_icon.svg";
import {ReactComponent as Facebook} from "../../../assets/icons/ic_facebook.svg";
import {ReactComponent as Linkedin} from "../../../assets/icons/linkedin_icon.svg";
import {ReactComponent as YouTube} from "../../../assets/icons/youtube_icon.svg";
import ApplicationStatus from "../../ApplicationPageComponents/ApplicationStatus/ApplicationStatus";
import ApplicationDetails from '../../ApplicationPageComponents/ApplicationDetails/ApplicationDetails';
import {postNewApplication, postTrialInfo, postNewInvisionApplication, leaveTheRate, getClientName} from "../../../Store/ApplicationsReducer/applicationsActions";
import {useDispatch, useSelector} from "react-redux";
import { getClientInfo, getCurrentApplicationData, getCurrentTrialApplicationData, addDetailsToApplicationInProcess } from '../../../Store/ApplicationsReducer/applicationsActions';
import React, {useEffect, useState} from "react";
import { ApplicationNotFoundTop } from '../../ApplicationPageComponents/ApplicationNotFound/ApplicationNotFound';
import TrialApplicationDetails from '../TrialAppliacationDetails/TrialApplicationDetails';
import { push } from 'connected-react-router';
import ApplicationSpecialist from '../../UI/ApplicationSpecialist/ApplicationSpecialist';
const InvisionTrialForm = (props) => {
    let left;
    let top;
    let center;
    let bottom;
    const params = new URLSearchParams(window.location.search);
    const dispatch = useDispatch();
    const id = props.match.params.id;
    const applicationHash = props.match.params.hash;
    const [fileNameState, setFileNameState] = useState([]);
    const refFile = useRef();
    const [isDisabledBtn, setIsDisabledBtn] = useState(false);
    const currentApplication = useSelector(state => state.applications.currentApplicationData);
    const trialInfo = useSelector(state=>state.applications.trialInfo)
    const [inputState, setInputState] = useState({
        id: id,
        topic: '',
        phone: "",
        context: '',
        email:'',
        date: '',
        files: [],
        personalData: false,
        fromCompany: "itsupport", 
        toCompany: "invision"
    });
    const [specialistComment, setSpecialistComment] = useState('')
    //пока дата в таком виде
    const [dayValue, setDay] = useState('День');
    const [monthsValue, setMonth] = useState('Месяц')
    const [reviewForm, toggleReviewForm] = useState(false);
    const [commentButtonVissibility, setCommentButtonVisibility] = useState(false)
    const [isLike, setIsLike] = useState(false)
    const [isDisLike, setIsDisLike] = useState(false)
    const [isHoverReview, setModalReview] = useState(false);
    let timer = null;
    let newApplication = currentApplication?.status === 'Запланировано';
    const parseTimerTime = (totalTime) => {
        if (totalTime === "expired") return 0
        const timeArray = totalTime.split(":")
        return ((parseInt(timeArray[0] * 60)) + parseInt(timeArray[1])) * 1000
    }
    let timerDuration=currentApplication?.timer ? parseTimerTime(currentApplication.timer) : null
    

    useEffect(() => {
        if (currentApplication?.rate===1) setIsLike(true);
        else if (currentApplication?.rate===-1) setIsDisLike(true);
        else {
            setIsLike(false);
            setIsDisLike(false);
        }
    }, [isDisLike, isLike]);

    const isLikeHandler = () => {
        setIsLike(true);
        setIsDisLike(false);
        let obj = {
            hash: applicationHash,
            rating: 1,
            comment: specialistComment,
            responsible_id: currentApplication.responsible_uuid,
        };
        dispatch(leaveTheRate(obj));
    };
    
    const isDisLikeHandler = () => {
        setIsDisLike(true);
        setIsLike(false);
        let obj = {
            hash: applicationHash,
            rating: -1,
            comment: specialistComment,
            responsible_id: currentApplication.responsible_uuid,
        };
        dispatch(leaveTheRate(obj));
    };
    const togglePopUp = () => {
        toggleReviewForm(!reviewForm);
    };

    useEffect(() => {
        if (currentApplication?.rate === 1) {
            setIsLike(true);
            setIsDisLike(false);
        } else if (currentApplication?.rate===-1) {
            setIsDisLike(true);
            setIsLike(false);
        } else {
            setIsLike(false);
            setIsDisLike(false);
        }
        // if (currentApplication.comment) {
        //     setIsComment(true);
        // }
        currentApplication?.comment? setCommentButtonVisibility(true): setCommentButtonVisibility(false);
    }, [currentApplication?.comment, isDisLike, isLike]);
    

    const checkboxHandler = ev => {
        setInputState(prevState => {
            return {...prevState, personalData: ev.target.checked}
        });
    }

    const textHandler = (ev) => {
        const {name, value} = ev.target;
        if (name === 'topic') {
            
            setInputState(prevState => {
                return {...prevState, topic: value, invisionTrial: true, avitimTrial: false}
            })
        } else if (name === 'context') {
            setInputState(prevState => {
                return {...prevState, context: value}
            })
        }else if (name === 'email') {
            setInputState(prevState => {
                return {...prevState, email: value}
            })
        }
    }
    
    const clearInputState = () => {
        setInputState({
            id: id,
            phone:'',
            topic: '',
            email: '',
            context: '',
            date: '',
            files: [],
            personalData: false,
            fromCompany: "itsupport", 
            toCompany: "invision"
        });
    }
    const [oneComment, setOneComment] = useState("");
    const applyComment = async () => {
        if (oneComment === undefined || oneComment.trim() === '') {
            return;
        }

        const obj = {
            hash: applicationHash,
            comment: oneComment
        }
        await dispatch(addDetailsToApplicationInProcess(obj))
        setOneComment('')
        await dispatch(getCurrentApplicationData(applicationHash))
    }
    const applyCommentToSpecialist = async (e) => {
        // e.preventDefault();
        if (specialistComment === undefined || specialistComment.trim() === '') {
            return;
        }
        const rating = isLike ? 1 : isDisLike ? -1 : 0;
        const obj = {
            hash: applicationHash,
            rating,
            comment: specialistComment,
            responsible_id: currentApplication.responsible_uuid,
        }
        await dispatch(leaveTheRate(obj))

        toggleReviewForm(false)
        setCommentButtonVisibility(true)
        togglePopUp(false)
    }

    const clientName = useSelector(state=>state.applications.clientName.name)
    const clientPhone = useSelector(state => state.applications.clientName.phone)
    const trialPhone = useSelector(state=> state.applications.clientPhone)
    const currentState = useSelector(state=> state);
    useEffect(()=>{
        dispatch(getClientName(id))
    }, [inputState.id, dispatch])

    useEffect(() => {
        
        dispatch(getClientInfo(id))
        if (inputState.topic && inputState.context && inputState.personalData) {
            setIsDisabledBtn(true);
        }

    }, [inputState])


    const textAreaHandler = (event) => {
        setOneComment(event.target.value);
    }

    const days = (
        <select className='TrialForm__Select' onChange={(e) => {
            setDay(e.target.value)
        }}>
            <option style={{"display":"none"}}>1</option>
            <option>1</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
            <option>6</option>
            <option>7</option>
            <option>8</option>
            <option>9</option>
            <option>10</option>
            <option>11</option>
            <option>12</option>
            <option>13</option>
            <option>14</option>
            <option>15</option>
            <option>16</option>
            <option>17</option>
            <option>18</option>
            <option>19</option>
            <option>20</option>
            <option>21</option>
            <option>22</option>
            <option>23</option>
            <option>24</option>
            <option>25</option>
            <option>26</option>
            <option>27</option>
            <option>28</option>
            <option>29</option>
            <option>30</option>
            <option>31</option>
        </select>
    );

    const months = (
        <select className='TrialForm__Select'  onChange={(e)=>{setMonth(e.target.value)}}>
            <option style={{"display":"none"}}>1</option>
            <option>январь</option>
            <option>февраль</option>
            <option>март</option>
            <option>апрель</option>
            <option>май</option>
            <option>июнь</option>
            <option>июль</option>
            <option>август</option>
            <option>сентябрь</option>
            <option>октябрь</option>
            <option>ноябрь</option>
            <option>декабрь</option>
        </select>
    )
    const chooseFile = (event) => {
        const fileNameStateCopy = [...fileNameState]
        for (let i = 0; i < event.target.files.length; i++) {
            fileNameStateCopy.push(event.target.files[i].name)
            setFileNameState(fileNameStateCopy);
            const filesCopy = inputState.files
            filesCopy.push(event.target.files[i])
            setInputState(prevState => {
                return {...prevState, "files": filesCopy}
            });
        }
    }
    const deleteFile = (i) => {
        const fileNameStateCopy = [...fileNameState]
        fileNameStateCopy.splice(i, 1)
        setFileNameState(fileNameStateCopy)
        const filesCopy = inputState.files
        filesCopy.splice(i, 1)
        setInputState(prevState => {
            return {...prevState, "files": filesCopy}
        });
    }
    let fileListBlocks;
    if (fileNameState.length) {
        fileListBlocks = fileNameState.map((el, i) => {
            return <div key={i} className="FileListBlocks__item">
                <p className="FileListBlocks__text">{el}</p>
                <div onClick={() => {deleteFile(i)}} className="FileListBlocks__iconDelete" />
            </div>
        })
    }
    // const currentApplication = useSelector(state => state.applications.currentApplicationData)

    const submitFormHandler = async (event) => {
        event.preventDefault();
        inputState.date = (dayValue +" "+ monthsValue)
        inputState.phone = trialPhone;

        params.get('fromCompany')==="avitim"?inputState.fromCompany='avitim':inputState.fromCompany='itsupport'
        const formData = new FormData();
        Object.keys(inputState).forEach(key => {
            if (typeof inputState[key] === 'object' && inputState[key] !== null) {
                for (let i = 0; i < inputState[key].length; i++) {
                    formData.append(key, inputState[key][i], inputState[key][i].name);
                }
            } else {
                formData.append(key, inputState[key]);
            }
        });
        
       

        // let data = {};
        // formData.forEach((value, key) => {data[key] = value});
        // let json = JSON.stringify(data);
        // let req = JSON.parse(json)

        dispatch(postNewInvisionApplication(formData, id, true)); 


        //company - из какой компании пришел
        dispatch(postTrialInfo(
            {
                ...trialInfo, phone: trialPhone, company: params.get('fromCompany')==='avitim'?'avitim':'itsupport', invisionTrial: true
            }
        ))
        clearInputState();
    }

    useEffect(() => {
        if (applicationHash) {
            dispatch(getCurrentTrialApplicationData(applicationHash));
        }
    }, [applicationHash])
    left = (
        <>
            <InvLogoSidebar/>
            <div className='TrialForm__Top'>
                <div className='TrialForm__Logo_Block'>
                    <LogoFrameInv logoLink={
                        params.get('fromCompany')==='avitim'?
                        ()=>{window.location.assign(`https://asista.avitim.kz/prelogin/${id}`)}:
                        ()=>{dispatch(push(`/prelogin/${id}`))}
                        } trialBackgroundColor='#F3F6F9'/>
                    <div className='TrialForm__Topic_Text_Block'>
                        <p className='TrialForm__Topic_Text'>Бесплатная пробная версия</p>
                    </div>
                </div>
                {/* <div className='TrialForm__Footer_Block'>
                    <div className='TrialForm__Footer_Title_Block'>
                        <p className='TrialForm__Footer_Title_Text'>Мы в социальных сетях</p>
                    </div>
                    <div className='TrialForm__Footer_SocialMedias'>
                        <Instagram/>
                        <Facebook/>
                        <Linkedin/>
                        <YouTube/>
                    </div>
                </div> */}
            </div>
        </>
    )
    if (currentApplication?.result) {
        if (currentApplication.humanId) {
            top = (
                <ApplicationSpecialist 
                    currentApplication = {currentApplication} 
                    isLike = {isLike}
                    isDisLike = {isDisLike}
                    isLikeHandler = {isLikeHandler}
                    isDisLikeHandler = {isDisLikeHandler}
                    isHoverReview={isHoverReview}
                    togglePopUp = {togglePopUp}
                    commentButtonVissibility = {commentButtonVissibility}
                    clientID={id}
                    setReviewFormFalse = {()=>toggleReviewForm(false)}
                    reviewForm = {reviewForm}
                    needTimer={false}
                    onChangeComment={
                        setSpecialistComment
                    }
                    commentValue={specialistComment}
                    applyCommentToSpecialist = {()=>applyCommentToSpecialist()}
                    clientName={clientName.name}
                    newApplication = {newApplication}
                />
            )
        } else {
            top = (
                <ApplicationNotFoundTop/>
            );
        }
    }

    if (currentApplication?.result) {
         center = (
            <TrialApplicationDetails
                department={currentApplication.division}
                subject={currentApplication.topic}
                message={currentApplication.body}
                result={currentApplication.eventresult}
                showDetailsButton={currentApplication.body ? currentApplication.body.length > 100 : null}
                showResultButton={currentApplication.eventresult ? currentApplication.eventresult.length > 100 : null}
                oneComment={oneComment}
                onChangeComment={(event) => {
                    textAreaHandler(event)
                }}
                submitComment={applyComment}
                idInTitle={currentApplication ? currentApplication.humanId : null}
                jobDone={currentApplication.status === 'Завершено'}
                isCanceled={currentApplication.status === 'Отменено'}
            />
        )
    } else {
    center = (
        <form className='TrialForm__Center' onSubmit={(e) => {
            submitFormHandler(e)
        }}>
            <div className='TrialForm__Name_Info'>
                <p className='TrialForm__Name_Info_Text'><strong>{clientName? clientName: 'Anonymous'}</strong> здравствуйте! Опишите свою
                    проблему
                </p>
            </div>
            <div className='TrialForm__Topic_Top_Block'>
                <div className='TrialForm__Topic_Block'>
                    <p className='TrialForm__Topic_Text'>Тема*</p>
                </div>
                <div>
                    <input className='TrialForm__Problem_Input' required name='topic' onChange={(e) => {
                        textHandler(e)
                    }} type='text'
                           placeholder='Опишите кратко суть проблемы'/>
                </div>
                <div className='TrialForm__Textarea__Box'>
                    <textarea className='TrialForm__Textarea' required name='context' onChange={event => {
                        textHandler(event)
                    }}
                              placeholder='Расскажите подробнее, например: утром вайфай еще работал, а после обеда выключается
                         каждые пять минут отправляю письма, а они не доходят до получателей. Можно прикрепить к
                         сообщению снимок экрана. Это поможет нам разобраться в проблеме.'/>
                </div>
                <div className='TrialForm__Textarea__Box'>
                    <input className='TrialForm__Problem_Input' required name='email' type='email' onChange={event => {
                        textHandler(event)
                    }}
                        placeholder='Введите email'/>
                </div>
                <div className='TrialForm__File'>
                    <Clip/>
                    <input type='file' multiple ref={refFile} onChange={chooseFile} className='TrialForm__Clip_File'/>
                    <p className='TrialForm__File__ChooseText'>Добавить файлы</p>
                    <div className='TrialForm__MoreInfo'>?</div>
                </div>
                <div className="FileListBlocks__block">
                        {fileListBlocks}
                </div>
            </div>
            <div className='TrialForm__Body_Block'>
                <div className='TrialForm__Body_Topic_Block'>
                    <p className='TrialForm__Topic_Text'>Выбрать дату консультации</p>
                </div>
                <div className='TrialForm__PickDate_Block'>
                    <div className='TrialForm__Timezone_Block'>
                        {days}
                        <p className='TrialForm__Timezone_Text'>{dayValue}</p>
                    </div>
                    <div className='TrialForm__Timezone_Block'>
                        {months}
                        <p className='TrialForm__Timezone_Text' >{monthsValue}</p>
                    </div>
                    <div className='TrialForm__Submit'>
                        <button type='submit'
                                style={isDisabledBtn ? {backgroundColor: '#4FC6E0'} : {backgroundColor: '#A7E3EF'}}
                                disabled={!isDisabledBtn} className='TrialForm__Submit_Button'>Отправить заявку
                        </button>
                    </div>
                </div>
                <div className='TrialForm__Personal_Data'>
                    <div className='TrialForm__Personal_Checkbox_Block'>
                        <input required type='checkbox' onChange={(e) => {
                            checkboxHandler(e)
                        }} className='TrialForm__Personal_Checkbox'/>
                    </div>
                    <div className='TrialForm__Personal_Data_Text_Block'>
                        <p className='TrialForm__Personal_Data_Text'>Согласие на обработку персональных данных</p>
                    </div>
                </div>
            </div>
        </form>
    )
    }

    bottom = (
        <div className='TrialForm__Bottom'>
            <FooterInfo/>
            <ITSGContact/>
        </div>
    )

    return (
        <div className='TrialForm__Layout'>
            <div className='TrialForm__Body'>
                {left}
                <div className='TrialForm__Body__Center'>
                    {top}
                    {center}
                </div>
            </div>
            {bottom}
        </div>
    )
}

export default InvisionTrialForm;