
import axios, { biApi } from '../../axiosApi';
import {
    PASSWORD_LOGIN_ERROR,
    PHONE_LOGIN_ERROR,
    SET_LOGIN_STATUS,
    SMS_LOGIN_ERROR,
    USERNAME_LOGIN_ERROR,
} from "./usersActionTypes";
import { push } from 'connected-react-router';
import { saveHash } from "../ApplicationsReducer/applicationsActions";
import { CONFIG } from "../../constants/backendUrl";


export const setLoginStatus = (value) => ({ type: SET_LOGIN_STATUS, value });

export const usernameLoginError = (value) => ({ type: USERNAME_LOGIN_ERROR, value });
export const passwordLoginError = (value) => ({ type: PASSWORD_LOGIN_ERROR, value });
export const phoneLoginError = (value) => ({ type: PHONE_LOGIN_ERROR, value });
export const smsLoginError = (value) => ({ type: SMS_LOGIN_ERROR, value });

export const saveUser = (device_id) => {
    return async dispatch => {
        try {
            await biApi.get(`/api/login/phone/${device_id}`);
        } catch (err) {
            console.log(err);
        }
    }
}
export const apiSendSMS = (dto, device_id) => {
    return async dispatch => {
        try {
            const resp = await biApi.post(`/api/login/send-sms`, { device_id, ...dto });
            if(resp.data.result) dispatch(setLoginStatus("sms"));
            else  dispatch(phoneLoginError("denied"));
        } catch (err) {
            dispatch(phoneLoginError("denied"));
        }
    }
}
export const sendSms = (smsAndId) => {
    return async dispatch => {
        try {
            const resp  = await biApi.post(`/api/login/check-sms`, smsAndId);
            await dispatch(saveHash(resp.data.hash));
            await dispatch(push('/search'));
            dispatch(smsLoginError(null));
        } catch (err) {
            dispatch(smsLoginError("denied"));
        }
    }
}
export const logout = () => {
    return dispatch => {
        try {
            dispatch(saveHash(""));
        } catch (err) {
            console.log(err);
        }
    }
}
export const prelogin = (id) => {
    return async dispatch => {
        try {
            await axios.get(`${CONFIG['apiUrl']}/prelogin/${id}`);
        } catch (err) {
            console.log(err);
        }
    }
}

export const loginUser = (login, password) => {
    return async dispatch => {
        try {
            const response = await biApi.post('/api/login/asista', { login, password });
            await dispatch(saveHash(response.data.hash));
            await dispatch(push('/search'));
        } catch (error) {
            try {
                dispatch(usernameLoginError(error));
                dispatch(passwordLoginError(error));
            } catch (err) {
                dispatch(push('/error'))
            }


        }
    }
}
