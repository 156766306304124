import { useDispatch, useSelector } from 'react-redux';
import { createVks } from '../../../Store/VksReducer/vksActions';
import './VCC.css';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import VksReadyModal from '../../VksInviteComponents/VksReadyModal/VksReadyModal';

export const VCC = (props) => {
    const dispatch = useDispatch()
    const {hasVks, vksLink, vksParts} = useSelector(state=>state.vks)
    const currentURL = window.location.protocol + '//' + window.location.host;
    const createVksHandler = () => {
        if(hasVks){
            const MySwal = withReactContent(Swal)
            MySwal.fire({
                title: 'Видеоконференции на портале Asista',
                html: 
                    <VksReadyModal currentURL={currentURL} vksParts={vksParts} vksLink={vksLink} id={props.id}/>,
                showConfirmButton: false,
                showCloseButton: false
            
            }).then(result => {
                if(result.isConfirmed){
                    window.location.assign(vksLink)
                }
            })
        }else{
            const MySwal = withReactContent(Swal)
            MySwal.fire({
                title: 'Видеоконференции на портале Asista',
                html: 
                    <div> 
                        Для одного клиента-компании мы создаем комнату для проведения внутренних конференций.
                        Вы можете отправить ссылку на нее всем участникам конференции и в указанное время решать рабочие вопросы.
                        Нажмите «Создать видеоконференцию», и вы автоматически попадете в комнату, созданную специально для вашей компании. 
                        <br/>
                        <br/>*Для абонентов IT Support Group данный сервис является бесплатным. Мы не используем персональные данные, озвученные в ходе видеоконференции.
                        <div className='Modal__buttons'>
                            <button type="button" onClick={()=>dispatch(createVks(props.id))} className="VksInviteModule__blue_button" >
                                Создать комнату
                            </button>
                            <button type="button" onClick={()=>Swal.close()} className="VksInviteModule__form_submit">
                                Отменить
                            </button>  
                        </div>
                    </div>,
                showConfirmButton: false,
                showCloseButton: false
            })
        }
    }
    return (
        <div style={hasVks? {"backgroundColor": "#fff", "zIndex": "4", "opacity": "100%", "borderRadius": "8px"} : {"backgroundColor": "#e0e0e0", "borderRadius": "8px"}}>
            <div className="VCC" >
                <div className = "VCC-logo"/>
                <div className="classification">
                    <p>Видеозвонки</p>
                    <p>Конференции</p>
                </div>
            </div>
            {hasVks ? 
                <div className="fill-inv" onClick={createVksHandler}>
                    <div className="vksHoverLogo-inv"/>
                </div>
                :
                <div className="fill-inv" onClick={createVksHandler}>
                    <p className='probe-text'>Создать видеоконференцию</p>
                </div>
            }
        </div>
        
    )
}